import React, { ReactNode, useEffect, useState } from "react";
import { Container, Navbar } from "reactstrap";
import MediaQuery from "react-responsive";
import Footer from "../../components/Footer";
import Header from "../Header";

interface LayoutProps {
  noHeader?: boolean;
  noCategory?: boolean;
  customHeaderComponent?: ReactNode;
  paymentHeader?: boolean;
  company_logo?: string;
  linkCode?: string;
  currencyCode?: string;
  amount?: number;
  showHeader?: any;
  campaign?: any;
  onlyXoxoCode: boolean;
  isMultiLangSupported: boolean;
  validity: string;
  isGvLink: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  showHeader,
  linkCode,
  company_logo,
  customHeaderComponent,
  children,
  noCategory,
  paymentHeader,
  currencyCode = "",
  amount = 0,
  campaign,
  onlyXoxoCode = false,
  isMultiLangSupported = false,
  validity,
  isGvLink,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 20) {
      setScrolled(true);
      document.body.classList.add("scrolling");
    } else {
      setScrolled(false);
      if (document.body.classList.contains("scrolling")) {
        document.body.classList.remove("scrolling");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      <Header
        linkCode={linkCode}
        company_logo={company_logo}
        currencyCode={currencyCode}
        amount={amount}
        scrolled={scrolled}
        showHeader={showHeader}
        campaign={campaign}
        onlyXoxoCode={onlyXoxoCode}
        isMultiLangSupported={isMultiLangSupported}
        validity={validity}
        isGvLink={isGvLink}
      />

      <Container style={{ top: "0" }} fluid></Container>
      <div className="container-fluid wrapper-padding">{children}</div>
      {/* <MediaQuery minDeviceWidth={992}>
				<Footer />
			</MediaQuery> */}
    </>
  );
};

export default Layout;
