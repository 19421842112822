import { ApiRoutesType, GraphQlQueryType } from "./api.types";

export const API_ROUTES: ApiRoutesType = {
  STORES: "/stores",
  NO_PAYU_EXP_UPDATE_ORDER: "noPayCheckout",
  STORESADMIN_CLIENTELE_CHECK: "/storeAdmin",
  PIP: "/plum_tp_integrations",
};

export const GRAPHQL_QUERIES: GraphQlQueryType = {
  EXPERIENCE: "stores.mutation.getExperiences",
  VOUCHER: "voucher.mutation.voucher_list",
  LINK_STATUS: "stores.query.link_status",
  FETCH_VOUCHERS: "stores.query.fetch_vouchers",
  PLUM_API: "stores.query.plumApi",
};
