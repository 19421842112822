import React, { useState } from "react";
// import {CLOUDINARY_URL} from '../../../../commons/constants'
import "../../home.scss";
import CardListModal from "./CardListModal";

import { stripHtml } from "../../../../utils/helpers";
import TncBack from "./TncBack";
import { Button } from "reactstrap";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";
import CollectVoucherCard from "./CollectVoucherCard";
import OnlyXoxoCode from "../OnlyXoxoCode";
import Card from "./Card";

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 36,
    minHeight: 36,
    border: "1px solid #BAC7D5",
  }),
};

const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;

let background = "";

interface Props {
  data?: any;
  campaign?: any;
  handleModal: Function;
  loading: boolean;
  module: number;
  handleHeader: any;
  xc_support: number;
  xc_data: any;
  fetchedLandingDetails: boolean;
  redemption_check: boolean;
  authenticate_email: boolean;
  maskedEmail: string;
  authenticate_phone: boolean;
  maskedPhone: string;
  delivery_method: number;
  linkCode: string;
  onlyXoxoCode: boolean;
  showCts: boolean;
  isGvLink: boolean;
  pIInfoRequired: boolean;
}
interface State {
  selected: any;
  showTnc: boolean;
  xc_selected: boolean;
  isUserVerified: boolean;
  loading: boolean;
  copytext: boolean;
  sendMailStatus: String;
  inputField: string;
  inputType: String;
  otpId: string;
  showSpecialRedemptionModal: boolean;
}
class Cards extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: 0,
      showTnc: false,
      xc_selected: false,
      isUserVerified: false,
      loading: false,
      copytext: false,
      sendMailStatus: "",
      inputField: "",
      inputType: "",
      otpId: "",
      showSpecialRedemptionModal: false,
    };
  }

  setShowSpecialRedemptionModal = (value: boolean) => {
    this.setState({
      showSpecialRedemptionModal: value,
    });
  };

  handleClick = (i: number, length: number) => {
    if (length >= 1) {
      this.setState({ selected: i + 1 });
    }
    document.body.scrollTop = 20; // For Safari
    document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Opera
    this.props.handleHeader(false);
  };

  handleXCSelected = () => {
    this.setState({ xc_selected: true });
    document.body.scrollTop = 20; // For Safari
    document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Opera
    this.props.handleHeader(false);
  };

  removeSelected = () => {
    this.setState({ selected: 0, xc_selected: false });
    this.props.handleHeader(true);
  };

  toggleTnc = () => {
    this.setState((prevState) => {
      return {
        showTnc: !prevState.showTnc,
      };
    });
  };

  handleHtml = (html: string) => {
    var temporalDivElement = document.createElement("span");
    temporalDivElement.innerHTML = html;
    html = temporalDivElement.textContent || temporalDivElement.innerText || "";
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  /* gridPattern[i] */
  setOtpId = (newOtpId: any) => {
    this.setState({
      otpId: newOtpId,
    });
  };

  setSelected = (newSelected: Number) => {
    this.setState({
      selected: newSelected,
    });
  };

  renderXoxoCodeGrid = () => {
    let self = this;

    let { xc_selected, selected, showTnc, isUserVerified, otpId } = this.state;
    let {
      campaign = {},
      loading,
      xc_data,
      redemption_check,
      delivery_method,
      linkCode = "",
      data,
      showCts,
      authenticate_email,
      maskedEmail,
      authenticate_phone,
      maskedPhone,
      isGvLink,
    } = this.props;

    return (
      <>
        {!xc_selected && (!selected || data.length === 1) && (
          <Card
            item={xc_data}
            handleClick={(e: any) => {
              self.handleXCSelected();
            }}
            data={xc_data}
            xc_selected={xc_selected}
            isGvLink={isGvLink}
          />
        )}
        {xc_selected ? (
          <CardListModal
            item={xc_data}
            selected={xc_selected}
            removeSelected={self.removeSelected}
            data={xc_data}
            handleHtml={self.handleHtml}
            campaign={campaign}
            isUserVerified={isUserVerified}
            redemption_check={redemption_check}
            customStyles={customStyles}
            xc_selected={xc_selected}
            xc_data={xc_data}
            code={xc_data.code}
            otpId={otpId}
            setOtpId={this.setOtpId}
            handleModal={self.props.handleModal}
            delivery_method={delivery_method}
            linkCode={linkCode}
            showCts={showCts}
            loading={loading}
            authenticate_email={authenticate_email}
            maskedEmail={maskedEmail}
            authenticate_phone={authenticate_phone}
            maskedPhone={maskedPhone}
            isGvLink={isGvLink}
          />
        ) : null}
      </>
    );
  };

  renderGrid = () => {
    let self = this;

    const {
      selected,
      xc_selected,
      isUserVerified,
      sendMailStatus,
      copytext,
      // disableBtn,
      otpId,
      showSpecialRedemptionModal,
    } = this.state;

    // var date = new Date(validity_date);
    // let formattedDate = this.getFormattedDate(date)
    let {
      campaign = {},
      loading,
      data = [],
      redemption_check = false,
      delivery_method = 1,
      xc_data,
      linkCode = "",
      showCts,
      authenticate_email,
      maskedEmail,
      authenticate_phone,
      maskedPhone,
      isGvLink,
      pIInfoRequired
    } = this.props;

    const selectedData = data.length === 1 ? data[0] : data[selected - 1] || {};

    return (
      <>
        {(!selected || data.length === 1) && !xc_selected && (
          <>
            {data.length === 1 ? (
              <div
                className="collect-voucher-card"
                style={{
                  backgroundColor: this.state.showSpecialRedemptionModal
                    ? "transparent"
                    : "",
                  minWidth: "auto",
                }}
              >
                <CollectVoucherCard
                  delivery_method={delivery_method}
                  linkCode={linkCode}
                  xc_selected={xc_selected}
                  handleModal={self.props.handleModal}
                  selected={selected === 0 ? 1 : selected}
                  item={data[0]}
                  handleHtml={self.handleHtml}
                  campaign={campaign}
                  isUserVerified={isUserVerified}
                  redemption_check={redemption_check}
                  authenticate_email={authenticate_email}
                  maskedEmail={maskedEmail}
                  authenticate_phone={authenticate_phone}
                  maskedPhone={maskedPhone}
                  customStyles={customStyles}
                  otpId={otpId}
                  setOtpId={this.setOtpId}
                  toggleTnc={this.toggleTnc}
                  verify_mobile={selectedData.verify_mobile}
                  setSelected={this.setSelected}
                  setShowSpecialRedemptionModal={
                    this.setShowSpecialRedemptionModal
                  }
                  showCts={showCts}
                  loading={loading}
                  isGvLink={isGvLink}
                  pIInfoRequired={pIInfoRequired}
                />
              </div>
            ) : (
              data.map((item: any, i: number) => (
                <Card
                  key={`voucher-card-${i}`}
                  item={item}
                  i={i}
                  handleClick={self.handleClick}
                  data={data}
                  selected={selected}
                  xc_selected={xc_selected}
                  isGvLink={isGvLink}
                />
              ))
            )}
          </>
        )}

        {selected && data.length > 1 ? (
          <CardListModal
            item={data[selected - 1]}
            selected={selected}
            removeSelected={self.removeSelected}
            data={data}
            handleHtml={self.handleHtml}
            campaign={campaign}
            isUserVerified={isUserVerified}
            redemption_check={redemption_check}
            customStyles={customStyles}
            xc_selected={xc_selected}
            xc_data={xc_data}
            code={selectedData.code}
            handleModal={self.props.handleModal}
            delivery_method={delivery_method}
            linkCode={linkCode}
            otpId={otpId}
            setOtpId={this.setOtpId}
            verify_mobile={selectedData.verify_mobile}
            showCts={showCts}
            loading={loading}
            authenticate_email={authenticate_email}
            maskedEmail={maskedEmail}
            authenticate_phone={authenticate_phone}
            maskedPhone={maskedPhone}
            isGvLink={isGvLink}
          />
        ) : null}
      </>
    );
  };

  render() {
    let { selected, isUserVerified } = this.state;
    let { showTnc, xc_selected, otpId } = this.state;
    let {
      onlyXoxoCode,
      data,
      xc_support,
      xc_data,
      fetchedLandingDetails,
      campaign,
      module,
      handleModal,
      loading,
      handleHeader,
      redemption_check,
      delivery_method,
      linkCode,
      showCts,
      authenticate_email,
      maskedEmail,
      authenticate_phone,
      maskedPhone,
    } = this.props;

    return (
      <>
        {onlyXoxoCode && module !== 2 ? (
          <OnlyXoxoCode
            handleModal={handleModal}
            xc_data={xc_data}
            redemption_check={redemption_check}
            item={xc_data}
            selected={true}
            removeSelected={this.removeSelected}
            data={xc_data}
            handleHtml={this.handleHtml}
            campaign={campaign}
            isUserVerified={isUserVerified}
            customStyles={customStyles}
            xc_selected={xc_selected}
            code={xc_data.code}
            delivery_method={delivery_method}
            otpId={otpId}
            setOtpId={this.setOtpId}
            linkCode={linkCode}
            handleXCSelected={this.handleXCSelected}
            showCts={showCts}
            authenticate_email={authenticate_email}
            maskedEmail={maskedEmail}
            authenticate_phone={authenticate_phone}
            maskedPhone={maskedPhone}
          />
        ) : (
          <>
            <div className={`cardBlock ${showTnc ? "flip" : ""}`}>
              <div
                // onClick={() => this.setState({ selected: 0 })}
                className={`front blocks ${showTnc ? "absolute" : ""} `}
              >
                <div
                  className={`row ${
                    data.length >= 5 && !data[selected - 1]
                      ? ""
                      : "column-center"
                  } align-items-center ${
                    data.length === 1 ? "px-3" : "mob-center"
                  }`}
                >
                  {/* height-auto */}
                  {this.renderGrid()}
                  <div className="clear clearfix"></div>
                </div>
              </div>
            </div>
            {fetchedLandingDetails && xc_support && selected == 0 ? (
              <div
                className={`front blocks xc_support ${
                  showTnc ? "absolute" : ""
                }`}
              >
                <div
                  className={`row ${
                    !xc_selected
                      ? ""
                      : "column-center align-items-center mob-center"
                  }`}
                >
                  {xc_selected ? null : (
                    <div className="show-border col-12 xc_support-title">
                      <h3 className=" mt-3">
                        Don’t like the above options? Looking for products in
                        another geography or more variety?
                      </h3>
                      <p>
                        Get a Xoxo Code instead and redeem from over 2000+
                        brands across 70+ countries.
                      </p>
                    </div>
                  )}
                  {this.renderXoxoCodeGrid()}
                </div>
              </div>
            ) : null}
          </>
        )}

        {showTnc && (
          <TncBack
            showTnc={showTnc}
            toggleTnc={this.toggleTnc}
            data={
              xc_selected ? xc_data : selected ? data[selected - 1] : data[0]
            }
          />
        )}
      </>
    );
  }
}

export default Cards;
