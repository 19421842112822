import * as React from "react";
import MediaQuery from "react-responsive";
import Layout from "../../commons/components/Layout";
import MetaTags from "react-meta-tags";
import { toast } from "react-toastify";
import ApiService from "../../api/api.service";
import { withRouter, RouteComponentProps } from "react-router-dom";
import OtpValidationModal from "./OtpValidationModal";
import axios from "axios";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  Collapse,
  FormGroup,
} from "reactstrap";
import PageLoader from "../../commons/components/Loader/PageLoader";
import PageNotFound from "../../pages/pageNotFound/PageNotFound";
import "./claimReward.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { SrvRecord } from "dns";
import ButtonLoader from "../../commons/components/Loader/ButtonLoader";
import config from "../../../config/config.production.json";
const captchaBaseUrl = process.env.REACT_APP_NODE_SERVER || "";
declare const window: any;
interface Props {}
interface State {
  inputField: string;
  inputType: String;
  inputValidate: boolean;
  isCapthaVerified: boolean;
  automation_id: string;
  responseID: String;
  loading: boolean;
  isValidResponse: boolean;
  issubmitResponse: boolean;
  isDuplicate: boolean;
  isOneTimeToken: boolean;
  rewardAmount: String;
  companyLog: String;
  onSubmiting: Boolean;
  email: String;
  pltm_id: Number;
  app_code: String;
  showOtpValidation: boolean;
  OTP: String;
  otp_id: String;
  error: {};
  submitErrorMsg: String;
}

class ClaimReward extends React.Component<Props & RouteComponentProps, State> {
  state = {
    inputField: "",
    inputType: "",
    inputValidate: false,
    isCapthaVerified: false,
    automation_id: "",
    responseID: "",
    loading: false,
    isValidResponse: false,
    issubmitResponse: false,
    isDuplicate: false,
    isOneTimeToken: false,
    rewardAmount: "",
    companyLog: "/logo-192.png",
    onSubmiting: false,
    email: "",
    pltm_id: 9,
    showOtpValidation: false,
    OTP: "",
    otp_id: "",
    error: {},
    app_code: "",
    submitErrorMsg: "",
  };
  componentDidMount() {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${config.site_key}`;
    script.async = false;
    script.defer = true;
    document.body.appendChild(script);

    let param = this.props.match.params;
    let automation_id = Object(param)["automation_id"];
    let responseID = Object(param)["rid"];
    let app_code = Object(param)["app_code"];
    let strFirstThree = responseID.substring(0, 2);
    let onTimeToken = false;
    let pltm_id = 9;
    if (app_code == "decipher" && strFirstThree != "t_") {
      const queryParams = new URLSearchParams(this.props.location.search);
      const response_id = queryParams.get("response_id");
      responseID = response_id;
    }
    switch (app_code) {
      case "qualtrics":
        pltm_id = 16;
        break;
      case "survey_monkey":
        pltm_id = 9;
        break;
      case "hubspot":
        pltm_id = 11;
        break;
      case "decipher":
        pltm_id = 20;
        break;
      default:
        pltm_id = 9;
    }
    if (strFirstThree == "t_") {
      onTimeToken = true;
    } else {
      onTimeToken = false;
    }
    this.setState(
      {
        responseID: responseID,
        automation_id: automation_id,
        isOneTimeToken: onTimeToken,
        app_code: app_code,
      },
      () => {
        this.validateResponse();
      }
    );
  }

  validateResponse = () => {
    const { isOneTimeToken, app_code }: any = this.state;
    this.setState({ loading: true });
    const { automation_id = "", responseID = "" }: any = this.state;
    let payload = {};
    let urlObject = `/integration/anonymous/${app_code}/validateResponse`;
    if (!isOneTimeToken) {
      payload = {
        responseID: responseID,
        automation_id: automation_id,
      };
    } else {
      payload = {
        token: responseID,
        automation_id: automation_id,
      };
    }
    const data = ApiService.verifyResponseID(payload, urlObject);
    data.then((data: any) => {
      if (data && data.data && data.data.isValidResponse == true) {
        let isDuplicate = data.data.isDuplicate ? data.data.isDuplicate : false;
        if (isDuplicate) {
          this.setState({
            isValidResponse: true,
            isDuplicate: true,
          });
        } else {
          this.setState(
            {
              isValidResponse: true,
              isDuplicate: false,
              rewardAmount: data.data.denomination
                ? data.data.denomination
                : "INR 0",
              companyLog: data.data.logo ? data.data.logo : "/logo-192.png",
              email: data.data.email ? data.data.email : "",
              inputField: data.data.email ? data.data.email : "",
            },
            () => {
              //do nothing
            }
          );
        }
      } else {
        this.setState({
          isValidResponse: false,
        });

        setTimeout(() => {
          this.setState({ isValidResponse: false });
        }, 4000);
      }

      this.setState({ loading: false });
    });
  };

  validateInput = (event: any) => {
    let value = event && event.target && event.target.value;
    this.setState({ inputField: value, submitErrorMsg: "" });
    this.sendCopyMailValidate(value);
  };
  sendCopyMailValidate = (value: any) => {
    let inputField = value;

    let emailReg = /\S+@\S+\.\S+/;

    if (inputField === "") {
      this.setState({ inputType: "", inputValidate: false });
    } else {
      this.setState({ inputType: "email" });
      if (emailReg.test(inputField) === true) {
        this.setState({ inputValidate: true });
      } else {
        this.setState({ inputValidate: false });
        return false;
      }
    }
    // this.sendCopy()
  };
  onChange = (value: any) => {
    if (value == null || value == "") {
      this.setState({ isCapthaVerified: false });
    } else {
      this.setState({ isCapthaVerified: true });
    }
  };
  onSubmitButtonClicked = () => {
    if (this.state.isOneTimeToken) {
      this.setState({ onSubmiting: true });
      this.claimReward();
    } else {
      // else if (!this.state.isCapthaVerified) {
      //   toast.error("please verify capchea");
      // }
      if (!this.state.inputValidate) {
        toast.error("please enter valid email");
      } else {
        if (!this.state.onSubmiting) {
          let self = this;
          self.setState({ onSubmiting: true });
          window &&
            window.grecaptcha &&
            window.grecaptcha.ready(function () {
              window.grecaptcha
                .execute(config.site_key, { action: "submit" })
                .then(function (token: any) {
                  self.claimReward(token);
                });
            });
        }
      }
    }
  };
  claimReward = (captchaToken = "") => {
    //this.setState({ loading: true })
    const {
      automation_id = "",
      responseID = "",
      inputField,
      isOneTimeToken,
      app_code,
    }: any = this.state;
    let payload = {};
    if (isOneTimeToken) {
      payload = {
        email_id: inputField,
        automation_id: automation_id,
        token: responseID,
      };
    } else {
      payload = {
        email_id: inputField,
        automation_id: automation_id,
        responseID: responseID,
        captcha_token: captchaToken,
      };
    }
    let urlObject = `/integration/anonymous/${app_code}/submitResponse`;
    const data = ApiService.claimReward(payload, urlObject);
    data.then((data: any) => {
      this.setState({ onSubmiting: false });
      if (data && data.data && data.data.success == true) {
        if (data.data.otp_id) {
          this.setState({ otp_id: data.data.otp_id, showOtpValidation: true });
        } else {
          this.setState({
            issubmitResponse: true,
            submitErrorMsg: "",
          });
        }
      } else {
        if (data.data.errcode == "reCaptchaFail") {
          this.setState({ issubmitResponse: false });
          toast.error("Captcha validation fail");
        } else {
          let errorMsg =
            app_code == "hubspot"
              ? "Unable to validate. Please contact the sender of the email for further assistance."
              : "Unable to validate. Please contact the author of this survey for further assistance";

          this.setState({
            issubmitResponse: false,
            submitErrorMsg: errorMsg,
          });
        }

        setTimeout(() => {
          this.setState({ issubmitResponse: false });
        }, 4000);
      }
      //this.setState({ loading: false })
    });
  };
  imageClick = () => {
    window.open("https://stores.xoxoday.com/marketplace");
  };
  removeSelected = () => {
    this.setState({ showOtpValidation: false });
    // this.props.handleHeader(true);
  };

  onRewardSent = () => {
    this.setState({
      issubmitResponse: true,
      otp_id: "",
      showOtpValidation: false,
    });
  };

  onResend = (otpid: string) => {
    this.setState({ otp_id: otpid });
  };

  //   updateActivationState = () => {
  //     this.setState({showOtpValidation:false,OTP : "",})
  // }

  render() {
    let {
      loading,
      isValidResponse,
      issubmitResponse,
      isDuplicate,
      isOneTimeToken,
      onSubmiting,
      submitErrorMsg,
    } = this.state;
    return (
      <div className="content">
        {!loading ? (
          isValidResponse ? (
            <div className="box">
              {issubmitResponse || isDuplicate ? (
                <Row>
                  <Col xs="12">
                    <img src="/img/icons/reward-sent.svg" />
                  </Col>
                  <Col xs="12" className="title">
                    <span className="titleattribute">
                      Your reward is on the way!
                    </span>
                  </Col>
                  <Col xs="12">
                    <div className="sub-title">
                      {" "}
                      Your reward voucher will be delivered on your email
                      shortly.{" "}
                    </div>
                  </Col>
                  <Col xs="12" className="mb-4" style={{ paddingTop: "30px" }}>
                    <div className="sub-title">
                      Reward redemption instructions will be mentioned in the
                      email. Incase of any assistance contact us via
                      cs@xoxoday.com or message us on Whatsapp no +91 80 6191
                      5050
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12" className="title">
                    <span className="titleattribute">
                      {isOneTimeToken
                        ? "Preview of Reward Claim Landing Page"
                        : "Reward Claim Landing Page"}
                    </span>
                  </Col>

                  <Col xs="12" className="title">
                    <img
                      alt=""
                      src={this.state.companyLog}
                      width="50px"
                      className=""
                    />
                  </Col>

                  <Col xs="12" className="title">
                    <span className="titleattribute">
                      {this.state.app_code == "hubspot"
                        ? "Thank you for being awesome!"
                        : "Thank you for completing the survey!"}
                    </span>
                  </Col>
                  <Col xs="12">
                    <span className="sub-title">
                      You deserve a reward for this from our side
                    </span>
                  </Col>
                  <Col xs="12">
                    <span className="sub-title">
                      {this.state.app_code == "hubspot"
                        ? "Enter your email address to collect a digital reward of " +
                          this.state.rewardAmount
                        : "Enter your email address to receive a digital reward of " +
                          this.state.rewardAmount +
                          " for FREE"}
                    </span>
                  </Col>
                  <Col xs="12">
                    <div className="email">
                      <div className="email-title">
                        {!isOneTimeToken ? "Enter Email Address" : "Test Email"}
                      </div>
                      <input
                        type="text"
                        value={this.state.inputField}
                        onChange={this.validateInput}
                        placeholder="Email"
                        id="email"
                        className={
                          isOneTimeToken
                            ? "input-email testEmail"
                            : "input-email"
                        }
                        disabled={isOneTimeToken}
                      />
                      {submitErrorMsg ? (
                        <div className="error mb-2">{submitErrorMsg}</div>
                      ) : (
                        <div className="email-subtext">
                          {!isOneTimeToken
                            ? "The entered email ID will only be used for reward delivery"
                            : "This is a test email, please do not click on collect voucher on the reward redemption link to avoid wallet balance deduction."}
                        </div>
                      )}
                    </div>
                  </Col>

                  {/* !isOneTimeToken ? (
                    <Col
                      xs="12"
                      className="d-flex flex-direction-row justify-content-center"
                    >
                      <ReCAPTCHA sitekey={site_key} onChange={this.onChange} />
                    </Col>
                  ) : null */}
                  <Col xs="12" className="submit-Button-coloumn">
                    <button
                      className="submit-Button"
                      style={{ borderColor: "#c7417b" }}
                      onClick={this.onSubmitButtonClicked}
                    >
                      {onSubmiting ? <ButtonLoader /> : "Claim Reward"}
                    </button>
                  </Col>
                  <Col>
                    <div className="mb-4">
                      <span className="poweredby">Powered by </span>
                      &nbsp;
                      <img
                        src="/img/icons/social-media-icon/xoxodaylogo.svg"
                        style={{ cursor: "pointer" }}
                        onClick={this.imageClick}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <PageNotFound />
          )
        ) : (
          <PageLoader />
        )}
        {this.state.showOtpValidation ? (
          <OtpValidationModal
            selected={this.state.showOtpValidation}
            removeSelected={this.removeSelected}
            onRewardSent={this.onRewardSent}
            otp_id={this.state.otp_id}
            email={this.state.inputField}
            automation_id={this.state.automation_id}
            responseID={this.state.responseID}
            app_code={this.state.app_code}
            onResend={this.onResend}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(ClaimReward);
