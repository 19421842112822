import React from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";

interface Props {
  msg?: string;
  campaign?: any;
  orderId?: any;
  linkId?: any;
}
class InvalidCode extends React.Component<Props> {
  render() {
    return (
      <Modal
        isOpen={true}
        className="modal-wrapper error-modal"
        backdrop={false}
      >
        <ModalBody className="text-center modal-padding">
          <Row>
            <Col className="text-center">
              <div>
                <h1>
                  {" "}
                  <img
                    alt=""
                    src="/img/icons/warning-icon.svg"
                    width="50px"
                    className="mb-2"
                  />
                </h1>
                <h3>Uh Oh!</h3>
                <div className="pb-1">
                  {" "}
                  {this.props.linkId
                    ? `Order Reference ID: ${this.props.linkId}`
                    : this.props.orderId
                    ? `Order Reference ID: ${this.props.orderId}`
                    : ""}
                </div>
                <p className="mt-2">
                  {this.props && this.props.msg
                    ? this.props.msg === "Inactive link"
                      ? "Looks like the link that you are trying to view is currently inactive."
                      : this.props.msg
                    : "This url is invalid, please enter correct url and hit the Enter."}
                </p>
                <hr />

                <p>Contact Plum support if you need any help</p>

                <div className="d-flex justify-content-center">
                  <div className="mr-3">
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75234 3.83545H17.2438C17.9304 3.83545 18.4961 4.40889 18.4961 5.09561V5.47021L10.7828 9.64873C10.7809 9.64873 10.7789 9.6499 10.777 9.65107C10.7751 9.65186 10.7731 9.65264 10.7712 9.65264C10.6073 9.75029 10.4005 9.75029 10.2327 9.65264C10.23 9.65264 10.2253 9.65068 10.2214 9.64951L10.2172 9.64873L2.5 5.47021V5.09561C2.5 4.40889 3.06572 3.83545 3.75234 3.83545Z"
                        fill="#252A31"
                      />
                      <path
                        d="M2.5 6.35576L9.83867 10.3354C10.2444 10.5655 10.7517 10.5655 11.1574 10.3354L18.5 6.35576V14.9038C18.5 15.5905 17.9343 16.164 17.2477 16.164H3.75234C3.06572 16.164 2.5 15.5905 2.5 14.9038V6.35576Z"
                        fill="#252A31"
                      />
                    </svg>
                    <span>cs@xoxoday.com</span>
                  </div>
                  {/*    
                                <div>
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.513 9.46429V14.8214C17.5122 15.5316 17.2297 16.2124 16.7276 16.7146C16.2254 17.2167 15.5446 17.4992 14.8344 17.5H10.9869C10.8449 17.5 10.7086 17.4436 10.6081 17.3431C10.5077 17.2426 10.4512 17.1064 10.4512 16.9643C10.4512 16.8222 10.5077 16.6859 10.6081 16.5855C10.7086 16.485 10.8449 16.4286 10.9869 16.4286H14.8344C15.2605 16.4281 15.669 16.2586 15.9703 15.9573C16.2716 15.656 16.4411 15.2475 16.4416 14.8214V14.7293C16.2696 14.7903 16.0884 14.8215 15.9059 14.8214H14.8344C14.4083 14.8209 13.9998 14.6515 13.6986 14.3502C13.3973 14.0489 13.2278 13.6404 13.2273 13.2143V10.5357C13.2278 10.1096 13.3973 9.70113 13.6986 9.39983C13.9998 9.09854 14.4083 8.92906 14.8344 8.92858H16.4178C16.2832 7.45682 15.6005 6.08935 14.5052 5.09716C13.4099 4.10497 11.9818 3.56048 10.5039 3.5716C10.5012 3.57163 10.4987 3.57163 10.496 3.5716C9.01811 3.56053 7.59004 4.10504 6.49474 5.09722C5.39943 6.0894 4.71682 7.45684 4.58217 8.92858H6.16551C6.5916 8.92906 7.0001 9.09854 7.30139 9.39983C7.60268 9.70113 7.77216 10.1096 7.77265 10.5357V13.2143C7.77216 13.6404 7.60268 14.0489 7.30139 14.3502C7.0001 14.6515 6.5916 14.8209 6.16551 14.8214H5.09408C4.66799 14.8209 4.25949 14.6515 3.9582 14.3502C3.65691 14.0489 3.48743 13.6404 3.48694 13.2143V9.46429C3.48694 8.54563 3.6687 7.63604 4.02174 6.78793C4.37478 5.93981 4.89213 5.16992 5.54399 4.5226C6.19585 3.87529 6.96934 3.36334 7.8199 3.01624C8.67047 2.66914 9.5813 2.49375 10.4999 2.50017C10.5163 2.50007 10.5328 2.50001 10.5492 2.50001C12.3961 2.50013 14.1674 3.23392 15.4734 4.53996C16.7793 5.846 17.513 7.61733 17.513 9.46429Z" fill="#252A31" />
                                        </svg>

                                        <span>
                                            9876543210
                                        </span>
                                </div>
                            */}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default InvalidCode;
